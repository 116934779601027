/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Calculators ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={crumbs}
        toc={toc}
        articleClass="launch-page"
      >

        <ReactMarkdown
          source={`
  ## Calculators
  Don’t guess — calculate. With the help of these financial calculators, get an accurate picture of how various decisions will affect your overall financial health. Whether you’re making an investment, buying a car or building your savings, knowing your numbers is the most powerful tool.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Auto-C.jpg"
            title="Auto"
            alt="Red car"
            href={`${root}/Auto`}
            link="Check It Out"
          >
            <p>
            Buying a car is a big decision, so it’s important to have a clear understanding of what you can afford. These calculators can help with that.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Housing-C.jpg"
            title="Housing"
            alt="Hand holding keys"
            href={`${root}/Housing`}
            link="Check It Out"
          >
            <p>
            Buying a house is a big financial decision. Set yourself up for mortgage success by crunching the numbers with these calculators.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Investing-C.jpg"
            title="Investing"
            alt="Dollars being planted in dirt"
            href="https://www.investor.gov"
            link="Get Started"
          >
            <p>
            Stay on top of your investment goals, evaluate your risk tolerance and calculate whether you’re on track with financial tools and calculators from the SEC.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Investing-C.jpeg"
            title="Loan"
            alt="Couple signing papers"
            href={`${root}/Loan`}
            link="Check It Out"
          >
            <p>
            Taking out a loan can feel risky, but these calculators can give you a better idea of the impact a loan payment and interest may have on your finances.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PersonalFinance-C.jpg"
            title="Personal Finance"
            alt="Paperwork with credit cards"
            href={`${root}/PersonalFinance`}
            link="Check It Out"
          >
            <p>
            Get your household spending plan and personal finances in order with these calculators that help you see where your money is going.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Retirement-C.jpg"
            title="Retirement"
            alt="Two service people looking at framed service awards"
            href="https://militarypay.defense.gov/Calculators"
            link="Visit Now"
          >
            <p>
            Set a clear plan for your retirement savings goals. Track your progress with help from DoD military pay and benefits calculators.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Savings-C.jpg"
            title="Savings"
            alt="White piggy banks on green surface"
            href={`${root}/Savings`}
            link="Check It Out"
          >
            <p>
            Setting and reaching savings goals is a key step to financial readiness. Use these calculators to make savings plans for the future.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Tax-C.jpg"
            alt="US Individual Tax Return Form 1040 and pen"
            title="Tax"
            href="https://www.irs.gov/individuals/tax-withholding-estimator"
            link="Learn More"
          >
            <p>
            Take the guesswork out of tax withholdings and deductions. Visit IRS.gov to calculate how to set up your withholdings to avoid owing tax payments at the end of the year.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PersonalNetWorth.jpg"
            alt="Hands holding dollar symbol"
            title="Personal Net Worth"
            href="/ToolsAndAddRes/NetWorth"
            link="Get Started"
          >
            <p>
            Know your net worth to help build a financially stable future. Use the Personal Net Worth Tracker to monitor your assets and liabilities and create a net worth statement.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


