/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DebtDestroyerRoutes from './navy-resource-routes/debt-destroyer-routes';
import Calculators from '../pages/calculators/calculators';
import Auto from '../pages/calculators/auto';
import Loan from '../pages/calculators/loan';
import Savings from '../pages/calculators/savings';
import PersonalFinance from '../pages/calculators/personal-finance';
import Housing from '../pages/calculators/housing';
import Oops404Page from '../pages/oops-404';


function MultiRoute (props) {
    return (
      <Route
        path={`${props.path}`}
        render={(routeProps) => (
          <Switch>
            <Route
              exact
              path={`${routeProps.match.path}`}
              render={(rps) => React.cloneElement(props.component, { ...rps, crumbs: props.crumbs, toc: props.toc })}
            />

            <Route
              exact
              path={`${routeProps.match.path}/calculator/:calculator`}
              render={(rps) => React.cloneElement(props.component, { ...rps, crumbs: props.crumbs, toc: props.toc, isCalculator: true })}
            />
            <Route render={() => <Redirect to={`${routeProps.match.path}`} />} />
          </Switch>
        )}
      />
    );
}

export default function CalculatorRoutes({ root, crumbs, toc }) {
    const thisCrumbs = (crumbs ? `${crumbs},` : '') + `Calculators|${root}`;

    return (
      <div>
        <Switch>
          <Route exact path={root}>
            <Calculators crumbs={thisCrumbs} root={root} toc={toc} />
          </Route>

          <Route
            path={`${root}/PersonalFinance/DebtDestroyer`}
            render={(routeProps) => (
              <DebtDestroyerRoutes
                {...routeProps}
                root={root}
                crumbs={`${thisCrumbs},Personal Finance Calculators|${root}/PersonalFinance`}
                toc={toc}
              />
            )}
          />

          <MultiRoute component={<Auto />} path={`${root}/Auto`} crumbs={thisCrumbs} toc={toc} />

          <MultiRoute component={<Housing />} path={`${root}/Housing`} crumbs={thisCrumbs} toc={toc} />

          <MultiRoute component={<Loan />} path={`${root}/Loan`} crumbs={thisCrumbs} toc={toc} />

          <MultiRoute component={<Savings />} path={`${root}/Savings`} crumbs={thisCrumbs} toc={toc} />

          <MultiRoute component={<PersonalFinance />} path={`${root}/PersonalFinance`} crumbs={thisCrumbs} toc={toc} />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
