/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../../components/nonTOCPage';

import Link from '../../components/link';
import MarkLink from '../../components/markLink';
import ReactMarkdown from '../../components/markdown';
import NewsletterFormControl from '../../components/newsletterFormControl';

export default function MilitaryMoneyArchives (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Your Military Money eNewsletter Archives`}
        articleClass="subpage mos"
      >
        <ReactMarkdown
          source={`
## Your Military Money eNewsletter Archives
This monthly eNewsletter spotlights timely information, resources and tools to help service members and their families achieve personal financial readiness in support of mission readiness.

### Stay Up to Date on Financial News
Get the latest news and updates directly to your inbox. Subscribe today:
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <NewsletterFormControl />

        <ReactMarkdown
          source={`
### New!
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3d34b94">Read the latest edition of the Your Military Money eNewsletter<span className="fa fa-external-link" /></Link>

        <ReactMarkdown
          source={`
### View Past Editions of the Your Military Money eNewsletter:
**2025**
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ce0263">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3c8b88d">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2024</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3c51bc6">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3bcfca1">November<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3b6a04b">October<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3b00405">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a8f34e">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a32dbc">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/39d1a59">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/396b63a">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3908fe9">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/38a175d">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3856e39">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/379a4e5">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2023</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3726b8c">October<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/36c8325">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3690a1c">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3623852">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/35cc090">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/355adca">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/352c31c">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/34abf88">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3449cdd">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/340e5f8">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2022</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/339e5d8">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/337024b">November<span className="fa fa-external-link" /></Link></li>
        </ul>
      </Page>
    );
}
