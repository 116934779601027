/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../../components/nonTOCPage';

import Link from '../../components/link';
import MarkLink from '../../components/markLink';
import ReactMarkdown from '../../components/markdown';
import NewsletterFormControl from '../../components/newsletterFormControl';

export default function MilSpouseMoneyMissionArchives (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},MilSpouse Money Mission eNewsletter Archives`}
        articleClass="subpage mos"
      >
        <ReactMarkdown
          source={`
## MilSpouse Money Mission eNewsletter Archives
This monthly eNewsletter empowers military spouses to elevate their families by making informed money moves.

### Stay Up to Date on Financial News
Get the latest news and updates directly to your inbox. Subscribe today:
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <NewsletterFormControl />

        <ReactMarkdown
          source={`
### New!
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3d35052">Read the latest edition of the MilSpouse Money Mission eNewsletter<span className="fa fa-external-link" /></Link>

        <ReactMarkdown
          source={`
### View Past Editions of the MilSpouse Money Mission eNewsletter:
**2025**
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ce014a">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3c8b867">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2024</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3c51c1d">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3bcf885">November<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3b6a345">October<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3b0018e">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a96135">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a32a07">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/39d1b11">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/396b653">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/39091b1">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/38a17a6">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3854389">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/379a114">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2023</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/377382b">November<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/36d35c3">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/365f20a">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3607454">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/35e89e4">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/355cf9c">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/34d6ded">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/347d377">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3435052">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/33c7473">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2022</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3395327">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3344834">November<span className="fa fa-external-link" /></Link></li>
        </ul>
      </Page>
    );
}
