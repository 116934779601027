/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function FirstChildRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},First Child|${root}` : `First Child|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-101_-_af_first_child_20_-_course_introduction_v6 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PaperworkandAdministrativeTasks', 'Paperwork and Administrative Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-102_-_af_first_child_20_-_paperwork_and_administrative_tasks_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoFoundationalPlanningTopics', 'Transition to Foundational Planning Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-103_-_af_first_child_20_-_transition_to_foundational_planning_topics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UpdatingYourSpendingPlan', 'Updating Your Spending Plan', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-104_-_af_first_child_20_-_creating,_managing_and_updating_a_spending_plan_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ChildCarePlanning', 'Child Care Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-105_-_af_first_child_20_-_childcare_planning_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsandEmergencyFunds', 'Savings and Emergency Funds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-106_-_af_first_child_20_-_savings_and_emergency_funds_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoInsurance', 'Transition to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-107_-_af_first_child_20_-_transition_to_insurance_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IntroductiontoInsurance', 'Introduction to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-108_-_af_first_child_20_-_introduction_to_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TRICAREInsurance', 'TRICARE Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-109_-_af_first_child_20_-_tricare_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PropertyandCasualtyInsurance', 'Property and Casualty Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-110_-_af_first_child_20_-_property_and_casualty_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-111_-_af_first_child_20_-_life_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoIncomeTaxes', 'Transition to Income Taxes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-112_-_af_first_child_20_-_transition_to_income_taxes_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IncomeTaxes', 'Income Taxes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-113_-_af_first_child_20_-_income_tax_fundamentals_and_potential_changes_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoLonger-TermPlanningTopics', 'Transition to Longer-Term Planning Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-114_-_af_first_child_20_-_transition_to_longer-term_planning_topics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EducationFinancingServiceMemberandSpouse', 'Education Financing: Service Member and Spouse', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-115_-_af_first_child_20_-_service_member_and_spouse_education_financing_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, "FinancingYourChild'sEducation", "Financing Your Child's Education", crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-116_-_af_first_child_20_-_education_financing_-_child_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TheImportanceofPreparingforRetirement', 'The Importance of Preparing for Retirement', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-117_-_af_first_child_20_-_the_importance_of_preparing_for_retirement_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ComponentsoftheMilitaryRetirementSystem', 'Components of the Military Retirement System', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-118_-_af_first_child_20_-_components_of_the_military_retirement_system_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoSurvivorBenefitsandEstatePlanning', 'Transition to Survivor Benefits and Estate Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-119_-_af_first_child_20_-_transition_to_survivor_benefits_and_estate_planning_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SurvivorandDependentBenefits', 'Survivor and Dependent Benefits', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-120_-_af_first_child_20_-_survivor_and_dependent_benefits_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningOverview', 'Estate Planning Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-121_-_af_first_child_20_-_estate_planning_overview_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningTasks', 'Estate Planning Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-122_-_af_first_child_20_-_estate_planning_tasks_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/first-child/36-123_-_af_first_child_20_-_wrap_up_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFNewChild-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
